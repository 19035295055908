.timeline-container {
  position: relative;
  width: 100%;
  margin: 20px 0;
}

.timeline-bar {
  position: relative;
  height: 4px;
  background-color: #CDDAEF;
  margin: 0 auto 40px auto;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.timeline-bar span {
  position: relative;
  margin-top: 10px;
  transform: translateX(-50%);
}

.events-container {
  position: relative;
  height: 150px;
  margin-top: 20px;
}

.event-container {
  position: absolute;
  top: 0;
  text-align: center;
}

.event-line {
  width: 2px;
  height: 60px;
  background-color: #ffcccc;
  margin: 0 auto;
}

.event-bubble {
  width: 100px;
  height: 100px;
  background-color: #ff6347;
  border: 1px solid #CDDAEF;
  /* Customize the event bubble color */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.event-bubble:hover {
  background-color: #ff4500;
}

/* Tooltip styling */
.react-tooltip {
  max-width: 200px;
}