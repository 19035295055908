.myCustomModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  /* Set a high z-index to ensure it's on top of everything */
}

/* Content inside the modal */
.myCustomModal .modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Optional: Close button styles */
.myCustomModal .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
  color: white;
}