.progress-circle-container {
  position: relative;
  display: inline-block;
  text-align: center;
}

.progress-circle {
  transform: rotate(-90deg);
  /* Rotate the circle to start the progress from the top */
}

.progress-circle-bg {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(0.25turn);
  transform-origin: 50% 50%;
}

.progress-circle-bar {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(0.25turn);
  transform-origin: 50% 50%;
  stroke-linecap: round;
  /* Rounded edge for the progress bar */
}

.progress-circle-content {
  position: absolute;
  top: 38px;
  left: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.progress-value {
  font-size: 18px;
  font-weight: bold;
  /* color: #4caf50; */
}

.progress-label {
  font-size: 11px;
  color: #555;
  padding: 0 10px;
}

.progress-goal {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}