.calendar-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0;
}

.day-names-row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* 7 columns for the 7 days */
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.day-name {
  padding: 10px 0;
  width: 69px;
}

.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* 7 columns for the 7 days */
  gap: 10px;
}

.day-box {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.empty {
  visibility: hidden;
  /* Hide empty boxes before the first day */
}

.payout-day {
  background-color: #4CAF50;
  color: white;
}

.current-day {
  background-color: #FFE4C4;
  color: black;
  font-weight: bold;
}

.amount {
  margin-top: 5px;
  font-size: 11px;
  color: #FDFDFD;
}