.no-border {
  border: none !important;
}

.table tr.no-border td {
  border-bottom-width: 0;
}

.input-label {
  background-color: #F4F4F4 !important;
  color: #4A4A4A !important;
}

table.key-value thead tr td {
  text-align: center;
  background-color: #ECECEC;
  padding: 15px 10px;
  font-weight: bold;
  border: 1px solid #CCCCCC;
}

table.key-value tbody tr td {
  font-size: 12px;
}

table.key-value tbody tr td:first-child {
  border-top: 1px solid #CCCCCC;
  background-color: #F4F4F4;
  border-right: 1px solid #CCCCCC;
  border-left: 1px solid #CCCCCC;
  width: 40%;
  padding: 10px;
  color: #555555;
  border-bottom: 1px dotted #CCCCCC;
}

table.key-value tbody tr td:last-child {
  border-top: 1px solid #CCCCCC;
  padding: 10px;
  background-color: #FFFFFF;
  font-weight: bold;
  border-bottom: 1px dotted #CCCCCC;
  border-right: 1px solid #CCCCCC;
}

table.key-value tbody tr:last-child td {
  border-bottom: 1px solid #CCCCCC;
}

.card p {
  margin-bottom: 10px;
}

.card p:last-child {
  margin-bottom: 0;
}

.nav-item .nav-link {
  cursor: pointer;
}

.form-info-element {
  font-style: italic;
  font-size: 12px;
}

.hidden {
  display: none;
  visibility: hidden;
}

.form-legend {
  font-size: 12px;
  font-style: italic;
}

.validation-indicator {
  font-weight: bold;
  color: #D9534F;
}

.react-datepicker-popper {
  z-index: 50 !important;
}

.small-notice {
  font-weight: bold;
  font-size: 14px;
}

.dropdown-item {
  cursor: pointer;
}

tr.moved-out td {
  background-color: #F0F0F0;
  color: #777777;
}

tr.highlight td,
tr.highlight th {
  background-color: #F4F4F4;
  color: #107938;
}

tr td.faded {
  color: #999999;
}

footer {
  margin-top: 30px;
  background-color: #E0E6F5;
  height: 150px;
  padding: 0 10px;
  line-height: 150px;
  color: #2C486F;
  border-top: 1px solid #8AA9D1;
  margin-bottom: 0;
  font-size: 12px;
}

.top-nav {
  position: absolute;
  right: 0;
  /* margin-top: 15px; */
}

.top-nav-container {
  width: 100%;
  margin-bottom: 0;
  background-color: #E0E6F5;
  min-height: 50px;
  padding: 10px 10px 0 10px;
  line-height: 50px;
  color: #2C486F;
  border-bottom: 1px solid #8AA9D1;
}

.notification-badge {
  margin-top: 35px;
  padding: 5px;
}

.task-due-date {
  color: #777777;
  font-size: 11px;
}

.horizontal-line {
  border-top: 1px solid #E0E6F5;
  width: 100%;
  height: 1px;
}

.logo {
  /* width: 80px; */
  font-size: 35px;
  text-decoration: none !important;
  border: 0;
  font-weight: bolder;
}

.logo .headline {
  font-size: 13px;
  margin: -20px 0 0 0;
  padding: 0;
  font-weight: normal;
}

.content-buttons button {
  font-size: 14px;
}

.form-switch {
  font-size: 14px;
}

button.btn-secondary {
  background-color: #017BFE;
}

div.form-control input.form-control {
  border: 0 !important;
  padding: 0 !important;
  width: 40% !important;
}

div.form-control:focus input.form-control:focus {
  outline: none !important;
  border: 0 !important;
}

.autocomplete-tag {
  background-color: #E0E6F5;
  padding: 0 5px 0 5px;
  color: #2C486F;
  border-radius: 5px;
}

.autocomplete-tag .autocomplete-tag-delete {
  font-weight: bold;
  cursor: pointer;
  margin-left: 5px;
  color: #DD3848;
}